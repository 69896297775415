import BasePage from '../../components/base-page/base-page';
import './page-not-found.scss';

class PageNotFound extends BasePage {

}

export default PageNotFound;

PageNotFound.initOnDomLoaded();
